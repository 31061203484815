// Override default variables before the import
$body-bg: #FFF;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

@include media-breakpoint-up(sm) {
  .logotitre {
    max-width: 100%;
  }
}
@include media-breakpoint-up(md) {
  .logotitre {
    max-width: 800px;
  }
}

.decolink {
  margin-top: 80px;
}